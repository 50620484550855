<template>
  <div >
    <div class="content-div content-div-1">
      <div class="hidden-md-and-down header" >
        <div class="d-flex justify-center flex-column">
          <div class="d-flex justify-end mt-n2">
            <h5 class="ma-0 fw-400" style="color:white">User :  Brandnographer2</h5>
          </div>
          <div class="d-flex mt-n3">
            <BoldLine :options="{color:this.$vuetify.theme.themes.light.secondary,height:'auto',width:'6px'}" :spacing="'x-stretch'"></BoldLine>
            <h2>Application Status</h2>
          </div>
        </div>
      </div>
      <div class="body pr-0">
        <p>The estimated (est.) provided below is only a reference to <a style="text-decoration:underline">standard procedures</a>. For enquiry, please contact us within office hours : 2186 8167/ llps@gov.hk. The whole process takes around 8-10 weeks.</p>
          <div class='o-table mb-2'>
            <!-- Ongoing -->
            <div class="o-tabs-card mt-6 mb-12" v-if="selected==0">
              <div class="top-header">
                <h3>ABC Bar</h3>
                <h5>Reference No: llps1234_2345555-12122</h5>
              </div>
              <div class="sec-header">
                <h6>Application Type</h6>
                <h6>Submission Date</h6>
                <h6>Status</h6>
                <div class="last-div"><h6>Form</h6></div>
                
              </div>
              <div class="content" v-for="(item,i) in ongoing_items" :key="i">
                <div>{{item.type}}</div>
                <div class="d-flex align-center">
                  <div>{{item.date}}</div>
                </div>
                <div>
                  <div class="mr-1" :class="statusToClass(item.status)">•</div>
                  <h6>{{item.status}}</h6>
                </div>
                <div>
                  <v-btn text class="simple-btn" color="primary" light>
                    Download
                  </v-btn>
                </div>
              </div>
              <div class="px-8 pb-8">
                <div class="callout-div rounded py-4 align-start" v-if="!infoError">
                  <v-icon color="primary" class="mt-1">mdi-information</v-icon>
                  <p class="my-0 ml-4">Waiting for Nominated Reserve Licensee to submit the “Nomination of Reserve Licensee FEHB 266” Form</p>
                </div>
                <div class="callout-div rounded error-box py-4 align-start flex-wrap" v-if="infoError">
                  <div class="d-flex align-start">
                    <v-icon color="#DB1D00" class="mt-1">mdi-information</v-icon>
                    <p class="my-0 ml-4">Document missing :</p>
                    <p class="my-0 ml-2"> A copy of layout plan <br/>(Deadline for re-submission : on or before 15-02-2021)</p>
                  </div>
                  <div class="d-flex align-start" >
                    <v-icon color="#DB1D00" class="mt-1">mdi-information</v-icon>
                    <p class="my-0 ml-4">Document missing :</p>
                    <p class="my-0 ml-2"> Please submit 3 newspaper advertisements <br/>(Deadline for re-submission : on or before 15-02-2021)</p>
                  </div>
                </div>
              </div>
              <div class="d-flex justfiy-center w-100 px-8">
                <BoldLine :options="{color:'#D2D2D2',height:'2px',width:'100%'}" ></BoldLine>
              </div>
              
              <div class="d-flex align-stretch o-f-stepper" style="max-width:940px;">
              <div v-for="(item,i) in items" :key="i" class="d-flex o-f-step">
                <div class="d-flex flex-column box align-center justify-center">
                  <div class="d-flex align-end " style="flex:1;"><h5 class="mb-2" style="text-align:center;" :class="item.active? 'step-active' : ''">{{item.title}}</h5></div>
                  <v-icon v-if="item.active" style="font-size:40px" color="primary">mdi-check-circle</v-icon>
                  <v-icon v-if="!item.active" style="font-size:40px" color="#E0E0E0">mdi-checkbox-blank-circle</v-icon>
                  <h5 v-if="item.remark != '' && item.active" class="em-16 mt-n1 fw-400" style="color:#4e45d1">🞃</h5>
                  <h5 v-if="item.remark =='' || !item.active" class="em-16 mt-n1 fw-400" style="color:transparent">*</h5>
                </div>
                <div class="connector mt-13" :style="!item.active?{background:'#E0E0E0'} :{}"></div>
              </div>
              </div>
              <div  class="ml-8 pb-8" v-if="items_current==0"> 
                <h2>Stage 1 : Application Submitted</h2>
                <p class="my-1">Est processing time on this stage : 1 week </p>
                <p class="my-1">Responsible Department : Licensing Office    </p>
                <p class="my-1">Case Officer's Contact : Ben Chan ( Tel: 2345 9834 / Email: benchan@llps.com )  </p>
                <p class="mt-4 pr-8">Under normal circumstances the application will pass to different departments within 1 week after the application receieved. You will be notified if there is any problem from different departmants. </p>
              </div>

              <div  class="ml-8 pb-8" v-if="items_current==1"> 
                <h2>Stage 2 : Department Feedback</h2>
                <p class="my-1">Est processing time on this stage : 2 weeks  </p>
                <p class="my-1">Responsible Department : Different Departments      </p>
                <p class="my-1">Case Officer's Contact : Ben Chan ( Tel: 2345 9834 / Email: benchan@llps.com )   </p>
                
                <div class="simple-table mt-4">
                  <p>Feedback from Different Department </p>
                  <p>Status</p>
                </div>
                <BoldLine :options="{color:'#D2D2D2',height:'2px',width:'100%'}" ></BoldLine>
                <div class="simple-table mt-4">
                  <p><strong>Hong Kong Police </strong></p>
                  <v-icon style="font-size:40px" color="#00A639">mdi-check-circle</v-icon>
                </div>
                <div class="simple-table mt-4">
                  <p><strong>Home Affairs Department </strong></p>
                  <v-icon style="font-size:40px" color="#E0E0E0">mdi-checkbox-blank-circle</v-icon>
                </div>
                <div class="simple-table mt-4">
                  <p><strong>Dist Env Hygiene Supt - Posting Notice </strong></p>
                  <v-icon style="font-size:40px" color="#E0E0E0">mdi-checkbox-blank-circle</v-icon>
                </div>
              </div>

            </div>
          </div>
        <router-link class="no-dec" :to="{ name: 'MAHome'}"><v-btn depressed class="o-btn-action rounded-pill mt-2 mr-5 mb-8" color="#413E56" dark>Back</v-btn></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BoldLine from '../../components/misc/bold-line'

// @ is an alias to /src


export default {
  name: 'MAHomeDetail',
  components: {
    BoldLine
  },
  data: function () {
    return {
      agree: false,
      selected:0,
      dialog: false,
      record:true, //  true: 6-2-1,  false: 6-1
      infoError:true, // true: red callout, false:purple callout(6-2-1)
      menu:false,menu_1:false,menu_2:false,
      items : [
        { title: 'Application Submitted', remark: ' ', active : true, },
        { title: 'Department Feedback', remark: ' ', active : true, }, // true: 6-3-2
        { title: 'Verifiy Documents', remark: ' ', active : false, },
        { title: 'Seek Approval', remark: ' ', active : false, },
        { title: 'Licence Ready', remark: ' ', active : false, },
        { title: 'Licence Issued', remark: ' ', active : false, },
      ],
      items_current:1, // 1: 6-3-2
      ongoing_items:[
        {type:'New Liquor Licence 2021-2022',date:'02-01-2021 18:00',status:'Application Submitted'}
      ],
      
    }
  },
  methods:{
    checkShouldShowApply: function (){
      if(!(this.partOne.selected==undefined) && !(this.partTwo[this.partOne.selected].selected==undefined)){
        return true;
      }else if (this.partOne.selected==1){
        return true;
      }
      return false;
    },
    statusToClass:function(status){
      if(status =='Approved'){
        return 'approved';
      }else if(status == 'Application Submitted'){
        return 'submitted'
      }
    },
  },
  mounted(){
    this.$emit('updateCurrent',0);
    this.dialog = true;
  },
}
</script>

<style scoped lang="scss">

.main-container {
    width:100%;
    height:100%;
    flex-direction: row;
    align-items: flex-start;
}

.o-table{
  width:100%;
  margin-left:-20px;
  padding-left:20px;
  .o-tabs-card{
    margin-right:80px;
    white-space: normal !important;
    min-width:950px;
  }
}

.landing-body{
  padding: 35px clamp(20px, 10%, 110px);
  background:white;
}

.simple-table{
  display: flex;
  align-items: center;
  margin:18px 0px;
  >p{
    margin:0px;
    &:first-child{
      min-width:500px;
    }
  }
}

.om{
  margin:32px 0px 0px;
}

.header{
  padding:56px 110px;
  min-height:170px;
}

//md and down
@media screen and (max-width: 1263px) {

.main-container {
    width:100%;
    height:100%;
    flex-direction: column;
}

.landing-body{
  width: 100vw;
  min-height:420px;
}

.o-tabs-card{
    white-space: normal !important;
    min-width:1000px;
  }

  

}

</style>
